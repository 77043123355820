import React ,{ useEffect, useState } from "react";  
export const ChatBotBottomOption = ({ handleOptionChange }) => {
    const [answerType, setAnswerType] = useState("one best answer")
    const OptionChange = (event) => {
        setAnswerType(event.target.value);
        handleOptionChange(event);
    };
    return (
        <div>
            <label>
                <input
                    type="radio" value="one best answer"
                    name="SelectOption"  className="rdBotOption"
                    checked={answerType === 'one best answer'}
                    onChange={OptionChange}
                />
               <span className="lblBotOption">One best answer</span> 
            </label>
            <label>
                <input
                    type="radio" value="10 best answers"
                    name="SelectOption" className="rdBotOption"
                    checked={answerType === '10 best answers'}
                    onChange={OptionChange}
                />
                  <span className="lblBotOption">10 best answers</span>  
            </label>
        </div>
    )
}