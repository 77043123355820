import { Formik, Field, ErrorMessage } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { Form, Card, Col, Button, ButtonToolbar, Modal } from "react-bootstrap"; 
import { Picky } from 'react-picky'; 
import { FormikFieldMultiSelectDropDown } from "../formik/formik_multiselectdropdown"; 
import { FormikFieldDropzone } from "../formik/formik_field_dropzone";
import { CommonUtils } from "../../actions/common_utils";




export const Document_Filter = ({ filters, onSubmit ,docTypes}) => {

    const [ShowHover, setShowHover] = useState(false);
    const [availdoctypes, setAvaildoctypes] = useState(false);
    const [Initfilters, setInitfilters] = useState(filters);
    const popupRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowHover(false)
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [])

    useEffect(() => {
        let defaultOption=[{id:"",name:"Select Type..."}, ...docTypes];
        setAvaildoctypes(defaultOption)
        setInitfilters(filters);
    }, [docTypes,filters]) 

    const OnSubmitClick = (values) => {
        onSubmit(values)
        setShowHover(false)
    }

    const OnResetClick = (values) => {
        setInitfilters({name:"",type:""});
        onSubmit({name:"",type:""})
        setShowHover(false)
    }

    return (
        <>
            <div class="dropdown filter_menu" ref={popupRef}>
                <div class="btn" onClick={() => { setShowHover(true) }}>
                    <i class="fa-solid fa-filter"></i>
                </div>
                {ShowHover && <div class="w-max dropdown-menu-end filter-dropdown">
                    <Formik
                        initialValues={Initfilters}
                        enableReinitialize={true}
                        onSubmit={OnSubmitClick}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            setFieldTouched,
                            handleSubmit,
                            touched,
                            resetForm,
                            errors,
                            handleBlur,
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>

                                <div className="col-lg-12">
                                <div className="form-group mb-3">
                                    <label className="form-label">Name</label>
                                        <Form.Control
                                            type="text"
                                            name={"name"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Name"}
                                            value={values.name}
                                        />
                                    </div>
                                    <div className="form-group mb-3">
                                    <label className="form-label">Select Type</label>
                                        <Form.Control
                                            required
                                            as="select"
                                            name={"type"}
                                            disabled={
                                                values.workflowRequired &&
                                                (values.workflowRequired === true ||
                                                    values.workflowRequired.toUpperCase() === "YES")
                                            }
                                            onChange={(value, e) => { 
                                                handleChange(value);
                                            }}
                                            onBlur={handleBlur}
                                            isInvalid={touched.type && !!errors.type}
                                            value={values.type}
                                        >
                                            {CommonUtils.getOptionsForSelect(availdoctypes)}
                                        </Form.Control>
                                    </div> 

                                    <div className="form-group mb-3">
                                    <label className="form-label">Last Updated By</label>
                                        <Form.Control
                                            type="text"
                                            name={"uploaderName"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Last Updated By..."}
                                            value={values.uploaderName}
                                        />
                                    </div>

                                    <div className="form-group mb-3">
                                    <label className="form-label">Comments</label>
                                        <Form.Control
                                            type="text"
                                            name={"comments"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder={"Enter Comments..."}
                                            value={values.comments}
                                        />
                                    </div>

                                    
                                   
                                    <ButtonToolbar>
                                        <button type="button" class="btn btn-primary" onClick={() => {
                                            resetForm();
                                            OnResetClick(values); 
                                        }}>Reset</button>
                                        <button type="submit" class="btn btn-primary">Filter</button>
                                    </ButtonToolbar>
                                </div>

                            </Form>
                        )}
                    </Formik>
                </div>}

            </div>
        </>
    )
}