/**
 * Created by pshivaraman on 3/29/18.
 */

import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {DISPLAY_NAME, logoutUser, ROLES, TOKEN_KEY, USER_NAME} from "../actions/auth_actions";
import {LinkContainer} from "react-router-bootstrap";
import {CUR_ORG, CURR_LANDING_PAGE, ORG_LANDING_PATH, SYS_LANDING_PATH} from "../actions/session_constants";
import TruVettingLogoDark from "../assets/images/TruVetting-Logo-Dark.png";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import {CommonUtils} from "../actions/common_utils";
import { withRouter } from "react-router-dom";
import User_password_reset_modal from "./user/user_password_reset_modal";
import AccountPasswordResetModal from "./account/account_password_reset_modal";
import { NotificationSettingModal } from "./account/notification_setting_modal";
 

class TruNav extends React.Component {
    constructor(props){
        super(props);
        this.state= {
            passwordModel:false,
            notificationModel:false
        }
    }

    onMenuItemSelect(key) {
        switch (key) {
            case "1.3":
                logoutUser();
                break;
            case "3":
                sessionStorage.removeItem(CUR_ORG);
                break;
            case "5":
                sessionStorage.setItem(CURR_LANDING_PAGE, ORG_LANDING_PATH);
                break;
            case "6":
                // sessionStorage.removeItem(CUR_ORG);
                sessionStorage.setItem(CURR_LANDING_PAGE, SYS_LANDING_PATH);
                break;
            case "1.1":
                this.setState({passwordModel: true});
                break; 
            case "1.2":
                this.setState({notificationModel: true});
                break; 
        }
    }

    switchOrgButton() {
        let path = this.props.location.pathname;
        let isInSysAdmin = false;
        if (path.startsWith('/sys/')) {
            isInSysAdmin = true;
        }
        if (sessionStorage.getItem(TOKEN_KEY) && !isInSysAdmin && sessionStorage.getItem(CUR_ORG)) {
            return (
                <Nav.Item>
                    <LinkContainer to="/org/list" exact>
                        <Nav.Link eventKey={3}><i className="fa fa-fw fa-exchange"/> Switch Organization</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            )
        }
    }

    tasksDueIcon = () => {
        let path = this.props.location.pathname;
        let isInSysAdmin = false;
        if (path.startsWith('/sys/')) {
            isInSysAdmin = true;
        }
        if (sessionStorage.getItem(TOKEN_KEY) && !isInSysAdmin && sessionStorage.getItem(CUR_ORG)) {
            return (
                <Nav.Item>
                    <LinkContainer to="/org/dash/task" exact>
                        <Nav.Link eventKey={7} title="Tasks Due"><i className="fa-solid fa-list-check"></i> Tasks</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            );
        }
    }

    switchRoleButton = () => {
        // If user has both org_admin and sys_admin roles
        if (CommonUtils.hasSysAdminRole() && CommonUtils.hasRole('ORG_ADMIN')) {
            // if user is logged in as org_admin then allow them to choose to switch to sys admin
            let path = this.props.location.pathname;
            if (path.startsWith('/sys/')){
                return (
                    <Nav.Item>
                        <LinkContainer to="/org/list" exact>
                            <Nav.Link eventKey={5}><i className="fa fa-fw fa-exchange"/> Switch to Org Admin Role</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                );
            } else {
                // .. and vice versa
                return (
                    <Nav.Item>
                        <LinkContainer to="/sys/dash" exact>
                            <Nav.Link eventKey={6}><i className="fa fa-fw fa-exchange"/> Switch to Sys Admin Role</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                );
            }
        }
    }

    accountButton() {
        let {notificationModel} = this.state;
        if (sessionStorage.getItem(TOKEN_KEY)) {
            // Logged In
            return (
                <NavDropdown title="Account" id="AccountDropdown" alignRight>
                    <NavDropdown.Item>
                        <h4>{sessionStorage.getItem(DISPLAY_NAME)}</h4>
                        {sessionStorage.getItem(USER_NAME)}
                    </NavDropdown.Item>
                    <NavDropdown.Divider/>
                    <NavDropdown.Item eventKey={1.1}><i className="fa fa-fw fa-key"/>Reset Password</NavDropdown.Item>
                    {sessionStorage.getItem(CUR_ORG) &&
                        <>
                            <NavDropdown.Item eventKey={1.2}><i className="fa fa-fw fa-bell" />Notification Settings</NavDropdown.Item>
                            <NotificationSettingModal showModal={notificationModel} onClose={this.closeModel} />
                        </>
                    }
                    {/*<NavDropdown.Item eventKey={1.2}><i className="fa fa-fw fa-gear"/> Settings</NavDropdown.Item>*/}
                    <NavDropdown.Divider/>
                    <NavDropdown.Item eventKey={1.3}><i className="fa fa-fw fa-power-off"/> Log Out</NavDropdown.Item>
                </NavDropdown>
            )
        } else {
            return (
                <Fragment>
                    <Nav.Item>
                        <LinkContainer to="/reporting/subject">
                            <Nav.Link eventKey={4}>My Security Management</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                    <Nav.Item>
                        <LinkContainer to="/login">
                            <Nav.Link eventKey={2}>Org Security Management</Nav.Link>
                        </LinkContainer>
                    </Nav.Item>
                </Fragment>
            )
        }
    }
    closeModel = () => { 
        this.setState({
            passwordModel: false,
            notificationModel: false
        });
    }
    render() {
        let { passwordModel , notificationModel } = this.state;
        return (
            <Navbar expand={'lg'} variant={'dark'} fixed={'top'}>
                <Navbar.Brand className="left-nav-wrapper">
                    <Link to="/">
                        <img
                            alt="Tru-Vetting"
                            src={TruVettingLogoDark}
                            height="40"
                        />
                    </Link>

                   
                </Navbar.Brand>

                <Navbar.Toggle/>

                <Navbar.Collapse>
                    <Nav/>
                    <Nav className={'ml-auto'} onSelect={this.onMenuItemSelect.bind(this)}>
                        {this.switchOrgButton()}
                        {this.switchRoleButton()}
                        {this.tasksDueIcon()}
                        {this.accountButton()}
                    </Nav>
                </Navbar.Collapse>
                <AccountPasswordResetModal showModal={passwordModel} onClose={this.closeModel}/>
                 
            </Navbar>
        )
    }
}

export default withRouter(TruNav);