/**
 * Created by pshivaraman on 7/27/18.
 */

import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import ToastGrowler from "./bootstrap_addons/toast-growler";
import Collapse from "react-bootstrap/Collapse";
import Fade from "react-bootstrap/Fade";

class Growler extends Component {
    constructor(props) {
        super(props);

        this.addToast = this.addToast.bind(this);
        this.nextId = 0;

        this.state = {
            toasts: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.msgs && nextProps.msgs.length > 0) {
            nextProps.msgs.forEach(this.addToast);
        }
    }

    addToast(message) {
        if (message) {
            const {summary, detail = null, severity = "info", closeable = true, life = 3000, sticky = false, icon = null} = message;
            const currentId = this.nextId;

            const handleClose = () => {
                this.setState({
                    toasts: this.state.toasts.filter((toast) => {
                        return currentId.toString() !== toast.key;
                    })
                });
            };

            const toast = <ToastGrowler severity={severity} key={this.nextId} autohide={!sticky} delay={life}
                                        onClose={handleClose} transition={Fade} closeable={closeable}
                                        summary={summary} detail={detail} icon={icon}/>

            this.nextId = this.nextId + 1;

            this.setState({
                toasts: this.state.toasts.concat(toast)
            });
        }
    }

    render() {
        return (
            <Fragment>
                <div className="toast-growler-container" style={{top: 70}}>
                    {this.state.toasts}
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps({msgs}) {
    return {
        msgs
    }
}

export default connect(mapStateToProps, {})(Growler);