import React, {Component} from "react";
import Nav from "react-bootstrap/Nav";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

export class Pager extends Component {
    render(){
        return (
            <Nav>
                <ul className="pager">
                    <ButtonToolbar>
                        {this.props.children}
                    </ButtonToolbar>
                </ul>
            </Nav>
        )
    }
}

export class PagerItem extends Component {
    render(){
        return (
            <li>{this.props.children}</li>
        )
    }
}