/**
 * Created by pshivaraman on 8/30/18.
 */

import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import filterFactory, {selectFilter, textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {DateUtils} from "../../../actions/common_utils";
import {addDocsToEntity, downloadDocument, fetchDocumentsByQueryNotIncluding} from "../../../actions/doc_actions";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {getDocTypes, getDocTypesByEntityType} from "../../../actions/fieldValue_actions";
import Modal from "react-bootstrap/Modal";
import {showErrorGrowl, showSuccessGrowl} from "../../../actions/msg_actions";

class DocAddTab extends Component {
    constructor(props){
        super(props);

        this.onTableChange = this.onTableChange.bind(this);
        this.addSelected = this.addSelected.bind(this);

        this.state = {
            avail_doc_types: [],
            page: 1,
            sizePerPage: 10,
            selected: []
        }
    }

    componentDidMount() {
        const {entityType, entityId} = this.props;
        const {page, sizePerPage} = this.state;
        this.props.fetchDocumentsByQueryNotIncluding(entityType, entityId, "", null, "", 0, 0, 0, 0, page - 1, sizePerPage);
        getDocTypes((response) => {
            this.setState({
                avail_doc_types: response.data.map(docType => {
                    return {
                        value: docType.id,
                        label: docType.type
                    }
                }).sort((a, b) => a.label.localeCompare(b.label))
            });
        });
    }

    getColumns() {
        const {viewDoc, entityType} = this.props;

        const headerFormatter = (column, colIndex, {sortElement, filterElement}) => {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div>
                        {column.text}
                        {sortElement}
                    </div>
                    {filterElement}
                </div>
            );
        };

        const makeLink = (docId, text) => {
            return <a href="#" onClick={() => viewDoc(docId)}>{text}</a>
        };

        let retColumnArr =  [{
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => makeLink(row.id, cell),
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }];

        if ("FACILITY" === entityType){
            retColumnArr.push({
                dataField: "locationsUsed",
                text: "Locations Used",
                formatter: (cell, row) => makeLink(row.id, cell? cell: ""),
                headerFormatter: headerFormatter
            });
        }
        retColumnArr.push({
            dataField: "type",
            text: "Type",
            formatter: (cell, row) => makeLink(row.id, row.type ? (row.type.id === "OTHER" ? "Other: " + row.otherType : row.type.type) : ""),
            sort: true,
            filter: selectFilter({
                options: this.state.avail_doc_types
            }),
            headerFormatter: headerFormatter
        }, {
            dataField: "dateUploaded",
            text: "Last Updated",
            formatter: (cell, row) => makeLink(row.id, DateUtils.getDateTimeString(cell)),
            sort: true,
            headerFormatter: headerFormatter
        }, {
            dataField: "uploaderName",
            text: "Last Updated By",
            formatter: (cell, row) => makeLink(row.id, cell),
            sort: true,
            filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: "actions",
            text: "Actions",
            formatter: (cell, row) => (
                <ButtonToolbar style={{flexWrap: "nowrap"}}>
                    <Button variant={'link'} className="close" onClick={() => downloadDocument(row.awsS3ObjectKey)}>
                        <span aria-hidden="true" title={"Download Document"} style={{padding: 10}} aria-label="Download Document">
                            <i className="fa fa-circle-down"/>
                        </span>
                    </Button>
                </ButtonToolbar>
            )
        });
        return retColumnArr;
    }

    onTableChange(type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) {
        const {entityType, entityId} = this.props;

        // Filters
        const nameFilter = filters.name ? filters.name.filterVal : "";
        const typeFilter = filters.type ? filters.type.filterVal : null;
        const uploaderNameFilter = filters.uploaderName ? filters.uploaderName.filterVal : "";

        // Sort
        let nameSort = 0;
        let typeSort = 0;
        let dateUploadedSort = 0;
        let uploaderNameSort = 0;

        const sortDir = sortOrder === 'asc' ? 1 : -1;

        switch (sortField) {
            case "name":
                nameSort = sortDir;
                break;
            case "type":
                typeSort = sortDir;
                break;
            case "dateUploaded":
                dateUploadedSort = sortDir;
                break;
            case "uploaderName":
                uploaderNameSort = sortDir;
                break;
        }

        // Request Object
        this.props.fetchDocumentsByQueryNotIncluding(entityType, entityId, nameFilter, typeFilter, uploaderNameFilter, nameSort,
            typeSort, dateUploadedSort, uploaderNameSort, page - 1, sizePerPage);

        this.setState({
            page: page,
            sizePerPage: sizePerPage
        });
    }

    addSelected(){
        const {selected} = this.state;
        const {entityType, entityId, handleClose, addDocsToEntity, showErrorGrowl, showSuccessGrowl} = this.props;

        if (selected && selected.length !== 0) {
            addDocsToEntity(selected, entityType, entityId, () => {
                handleClose();
                showSuccessGrowl("Documents Added Successfully", "The Documents were added successfully!");
            });
        } else {
            showErrorGrowl("No Documents Selected", "Select at least 1 Document to add.");
        }
    }

    render(){
        const {docs, totalSize} = this.props;
        const {page, sizePerPage} = this.state;

        if (!docs) {
            return (
                <div>Loading...</div>
            )
        }

        const remoteSettings = {
            filter: true,
            pagination: true,
            sort: true,
            cellEdit: false
        };

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            selected: this.state.selected,
            onSelect: (row, isSelect, rowIndex, e) => {
                if (isSelect) {
                    this.setState(() => ({
                        selected: [...this.state.selected, row.id]
                    }));
                } else {
                    this.setState(() => ({
                        selected: this.state.selected.filter(x => x !== row.id)
                    }));
                }
            },
            onSelectAll: (isSelect, rows) => {
                const ids = rows.map(r => r.id);
                if (isSelect) {
                    this.setState(() => ({
                        selected: ids
                    }));
                } else {
                    this.setState(() => ({
                        selected: []
                    }));
                }
            }
        };

        return (
            <Fragment>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={docs}
                                columns={this.getColumns()} filter={filterFactory()} remote={remoteSettings}
                                onTableChange={this.onTableChange}
                                pagination={paginationFactory({page, sizePerPage, totalSize: totalSize})}
                                selectRow={selectRow}
                />

                <Modal.Footer>
                    <Button onClick={this.props.handleClose}>Cancel</Button>
                    <Button variant="primary" onClick={this.addSelected}>Add Selected</Button>
                </Modal.Footer>
            </Fragment>
        )
    }
}

function mapStateToProps({doc_results}){
    return {
        docs: doc_results.data,
        totalSize: doc_results.totalSize
    }
}

export default connect(mapStateToProps, {fetchDocumentsByQueryNotIncluding, addDocsToEntity, showErrorGrowl, showSuccessGrowl})(DocAddTab);