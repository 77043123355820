import React, {Fragment} from "react";
import {connect} from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import {downloadDocument, fetchDocument, getDocUrl} from "../../actions/doc_actions";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {DateUtils} from "../../actions/common_utils";
import {Link} from "react-router-dom";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import DocAddRevisionModal from "./doc_add_revision_modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ConditionalDisplay} from "../../containers/util/shouldDisplay";

class DocDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //docUrl: "",
            showModal: false,
            selected: []
        };

        this.handleClose = this.handleClose.bind(this);
        this.getDocument = this.getDocument.bind(this);
    }

    componentDidMount() {
        this.setState({showModal: true});
        this.getDocument();
    }

    getDocument(){
        this.props.fetchDocument(this.props.docId);
    }

    getPreview() {
        // if (!this.state.docUrl) {
        //     return <div>Loading Preview...</div>
        // }
        // switch (this.state.docExt){
        //     case "csv":
        //     case "xslx":
        //     case "docx":
        //         return <FileViewer filePath={this.state.docUrl} fileType={this.state.docExt}/>;
        //     default:
        //         return <a href={this.state.docUrl} target={"_blank"}>Open Preview</a>
        // }
        return <Fragment />
    }

    getRevisions(){
        const headerFormatter = (column, colIndex, {sortElement, filterElement}) => {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div>
                        {column.text}
                        {sortElement}
                    </div>
                    {filterElement}
                </div>
            );
        };

        const expandRow = {
            onlyOneExpanding: true,
            showExpandColumn: true,
            expandByColumnOnly: true,
            expandColumnPosition: 'right',
            renderer: row => (
                <div>
                    <p>Comments: {row.comments}</p>
                </div>
            ),
            expandColumnRenderer: ({ expanded }) => {
                return (
                    <span className="close" aria-hidden="true" aria-label="Toggle Panel">
                        <i className={`fa fa-chevron-${expanded ? 'up' : 'down'}`}/>
                    </span>
                );
            },
            expandHeaderColumnRenderer: ({ isAnyExpands }) => {
                return "";
            },
        };

        const defaultSorted = [{
            dataField: 'number',
            order: 'desc'
        }];

        const columns = [
            {
                dataField: "number",
                text: "#",
                sort: true,
                headerFormatter: headerFormatter
            }, {
                dataField: "fileName",
                text: "File Name",
                sort: true,
                headerFormatter: headerFormatter
            }, {
                dataField: "effectiveDate",
                text: "Effective Date",
                formatter: (cell, row) => DateUtils.getDateString(cell),
                sort: true,
                headerFormatter: headerFormatter
            }, {
                dataField: "uploaderName",
                text: "Uploaded By",
                formatter: (cell, row) => <Link to={`/org/dash/subject/${row.uploader}`}>{cell}</Link>,
                sort: true,
                headerFormatter: headerFormatter
            }, {
                dataField: "actions",
                text: "Actions",
                formatter: (cell, row) => (
                    <ButtonToolbar style={{flexWrap: "nowrap"}}>
                        <Button variant={'link'} className="close" onClick={() => downloadDocument(row.awsS3ObjectKey)}>
                            <span aria-hidden="true" style={{padding: 10}} title={"Download Document"} aria-label="Download Document">
                                <i className="fa fa-circle-down"/>
                            </span>
                        </Button>
                    </ButtonToolbar>
                )
            }
        ];

        // const handleOnSelect = (row, isSelect) => {
        //     if (isSelect) {
        //         getDocUrl(row.gridFile).then((response) => {
        //             this.setState({
        //                 docUrl: response.data,
        //                 docExt: /(?:\.([^.]+))?$/.exec(row.fileName)[1],
        //                 selected: [row.number]
        //             });
        //         });
        //     }
        // }
        //
        // const selectRow = {
        //     mode: 'radio',
        //     clickToSelect: true,
        //     selected: this.state.selected,
        //     onSelect: handleOnSelect
        // };

        return (
            <Fragment>
                <div className="d-flex space-between mb-3">
                    <h3 className="page_title_left">Revisions</h3>
                    {ConditionalDisplay(
                        <ButtonToolbar>
                            <DocAddRevisionModal docId={this.props.docId} onFinish={this.getDocument} />
                        </ButtonToolbar>
                )}
                </div>
               
                <BootstrapTable bootstrap4={true} hover striped bordered={true} keyField="number" data={this.props.doc.revisions}
                    columns={columns} expandRow={expandRow} defaultSorted={defaultSorted} />
            </Fragment>
        )
    }

    handleClose(){
        this.setState({showModal: false});
        setTimeout(this.props.viewList, 500);
    }

    render() {
        const {doc} = this.props;

        return (
            <Modal show={this.state.showModal} onHide={this.handleClose} size={'lg'}>
                <Modal.Header closeButton>
                    <Modal.Title>Document Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!doc &&
                    <div>Loading...</div>
                    }
                    {doc &&
                    <div>
                        <h3 className="page_title_left">General</h3>
                        <Row className={'mb-3'}>
                            <Col sm={3} md={2} className="form-label">
                                Name
                            </Col>
                            <Col sm={3} md={4}>
                                {doc.name}
                            </Col>
                            <Col sm={3} md={2} className="form-label">
                                Type
                            </Col>
                            <Col sm={3} md={4}>
                                {doc.type ? doc.type.id === "OTHER" ? "Other: " + doc.otherType : doc.type.type : ""}
                            </Col>
                        </Row>

                       
                        {this.getRevisions()}

                        {/*<h4>Preview</h4>*/}
                        {/*{this.getPreview()}*/}
                    </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

function mapStateToProps({doc}) {
    return {
        doc
    }
}

export default connect(mapStateToProps, {fetchDocument})(DocDetail);