/**
 * Created by pshivaraman on 4/19/18.
 */

import React, {Component} from "react";
import {Link} from "react-router-dom";

class BreadcrumbLink extends Component {
    render(){
        const {to, children} = this.props;

        return (
            <li className="breadcrumb-item">
                <Link to={to}>
                    {children}
                </Link>
            </li>
        )
    }
}

export default BreadcrumbLink;