import React, {Component, Fragment} from "react";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";
import DocNewTab from "./doc_new";
import DocAddTab from "./doc_add";
import Card from "react-bootstrap/Card";
import { ModalBody } from "react-bootstrap";

class DocAddModal extends Component {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);
        this.onTabSelect = this.onTabSelect.bind(this);
        this.getTabContent = this.getTabContent.bind(this);

        this.state = {
            showModal: false,
            currentTab: "insert"
        }
    }

    componentDidMount() {
        if (this.props.docTypes && this.props.docTypes.length > 0){
            this.setState({
                showModal: true,
                currentTab: "create"
            })
        } else {
            this.setState({
                showModal: true,
                currentTab: "insert"
            });
        }

    }

    componentWillUnmount() {
        // this.setState({showModal: false});
    }

    handleClose() {
        this.setState({showModal: false});
        setTimeout(this.props.viewList, 500);
    }

    onTabSelect(key, event) {
        switch (key) {
            case "insert":
                this.setState({
                    currentTab: "insert"
                });
                break;
            case "create":
                this.setState({
                    currentTab: "create"
                });
                break;
        }
    }

    getTabContent() {
        const {entityType, entityId, onCreate, viewDoc, docTypes} = this.props;
        switch (this.state.currentTab) {
            case "insert":
                return <DocAddTab entityType={entityType} entityId={entityId} handleClose={this.handleClose}
                                  viewDoc={viewDoc}/>
            case "create":
                return <DocNewTab docUploadSuccessCallBack={this.props.docUploadSuccessCallBack} entityType={entityType} entityId={entityId} onCreate={onCreate} docTypes={docTypes}
                                  handleClose={this.handleClose}/>
        }
        return <Fragment/>
    }

    render() {
        return (
            <Modal show={this.state.showModal} onHide={this.handleClose} size={'lg'}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Documents</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    
                        <Nav variant="tabs" defaultActiveKey="insert" onSelect={this.onTabSelect}>
                            {(!this.props.docTypes || this.props.docTypes.length === 0) &&
                            <Nav.Item>
                                <Nav.Link eventKey={"insert"}>Insert</Nav.Link>
                            </Nav.Item>}
                            <Nav.Item>
                                <Nav.Link eventKey={"create"}>Create</Nav.Link>
                            </Nav.Item>
                        </Nav>
                   
                 
                        {this.getTabContent()}
                   
                        </ModalBody>
            </Modal>
        )
    }
}

export default DocAddModal;