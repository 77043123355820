import React, {Component, Fragment} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {showSuccessGrowl, showErrorGrowl} from "../../actions/msg_actions";
import {updateDocumentMetadata} from "../../actions/doc_actions";
import {connect} from "react-redux";
import {Formik, Field} from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import {FormikDatePickerComponent} from "../formik/formik_date_picker";
import PropTypes from 'prop-types';
import DeleteConfirmationModal from "../../components/delete_confirm_modal";
import { getDocTypes } from "../../actions/fieldValue_actions";
import { CommonUtils } from "../../actions/common_utils";
import { FieldRBSelectWithKeys } from "../redux_form/field_components_rb";

class DocAddRevisionModal extends Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);

    this.state = {
      showModal: false,
      docTypes: [],
    };
  }
  componentDidMount() {
    getDocTypes((response) => {
      const _docTypes = response.data.map((docType) => {
        return {
          id: docType.id,
          name: docType.type,
        };
      });
      this.setState({
        docTypes: _docTypes,
      });
    });
  }
  open() {
    this.setState({
      showModal: true,
    });
  }

    close = () => {
        this.setState({
            showModal: false,
        });
    }

    onSubmit = (values) => {
        values.id = this.props.document.id
        this.props.updateDocumentMetadata(values, () => {
            this.props.showSuccessGrowl("Document Update", "The document metadata has been updated successfully!");
            this.props.onFinish();
            this.close();
        }, (error) => {
            console.log(error);
            this.props.showErrorGrowl(`Document Update", "Unexpected error updating document metadata. Error is ${error.response.data}`);
        });
    }

    render() {
        const {showModal, docTypes} = this.state;
        const {document} = this.props;
        return (
            <Fragment>
                <Button variant={'link'} className="close" onClick={this.open}>
                        <span aria-hidden="true" title={"Edit Document"} style={{padding: 10}} aria-label="Edit">
                        <i className="fa fa-square-pen"></i>
                        </span>
                </Button>

                <Modal show={showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Document Metadata</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={{
                            "name": document.name,
                            "effectiveDate": (document.effectiveDate?document.effectiveDate:""),
                            "comments": document.comments,
                             "type": document.type.id,
                             "otherType": (document.otherType===null?'':document.otherType),
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                name: Yup.string().required('Document name is required'),
                                effectiveDate: Yup.date().required('Enter effective date for the document'),
                                type: Yup.string().required("Document Type is required"),
                                otherType: Yup.string().test('otherTypeRequired', 'Other Type is required', function(value) {
                                  if (this.parent.type === 'OTHER') {
                                    return !!value;
                                  }
                                  return true;  
                                })
                              })
                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Document Name</Form.Label>
                                            <Form.Control required type="text" name={'name'}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Document Name'}
                                                          isInvalid={touched.name && !!errors.name}
                                                          value={values.name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Effective Date</Form.Label>
                                            <Field
                                                id="effectiveDate"
                                                name="effectiveDate"
                                                component={FormikDatePickerComponent}
                                                placeholder={"Effective date of the document for use with compliance"}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Comments</Form.Label>
                                            <Form.Control required type="text" name={'comments'} onChange={handleChange}
                                                          onBlur={handleBlur} placeholder={'Comments'}
                                                          isInvalid={touched.comments && !!errors.comments}
                                                          value={values.comments}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.comments}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6">
                                          <Form.Label>Type</Form.Label>
                                          <Form.Control required  as="select" name="type" id="type"  onChange={handleChange}
                                                        onBlur={handleBlur} component={FieldRBSelectWithKeys}  
                                                        isInvalid={touched.type && !!errors.type}
                                                        value={values.type}
                                          >
                                                  {CommonUtils.getOptionsForSelect(docTypes)}
                                          </Form.Control>
                                          <Form.Control.Feedback type="invalid">
                                                  {errors.type}
                                          </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        {values.type != null && values.type === "OTHER" && (
                                          <Form.Group as={Col} md="6">
                                            <Form.Label>Other Type Notes</Form.Label>
                                            <Form.Control required type="text" name={"otherType"}
                                                          onBlur={handleBlur} onChange={handleChange} 
                                                          value={values.otherType} placeholder={"Other Type"}
                                                          isInvalid={touched.otherType && !!errors.otherType}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                  {errors.otherType}
                                            </Form.Control.Feedback>
                                          </Form.Group>
                                        )}
                                    </Form.Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <ButtonToolbar>
                                        <Button variant="primary" type="submit" disabled={isSubmitting}>Update</Button>

                                        <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Document Metadata"
                                                  handleDeleteCallback={this.close}/>
                                        {/* <Button variant="danger" onClick={this.close}>Cancel</Button> */}
                                    </ButtonToolbar>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </Fragment>
        )
    }
}

export const DocEditModal = connect(null, {
    showSuccessGrowl,
    showErrorGrowl,
    updateDocumentMetadata
})(DocAddRevisionModal);

DocEditModal.propTypes = {
    document: PropTypes.object.isRequired,
    subjectId: PropTypes.string.isRequired,
    onFinish: PropTypes.func.isRequired
}