import React, {Component, Fragment} from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import {Link} from "react-router-dom";

class BulkDocUploadProgress extends Component {
    // The elements in the table should show doc name, progress, subject name (after success), error if any
    render(){
        const {children, percents, uploading} = this.props;

        console.log('Percents are ', percents);
        const columns = [{
            dataField: 'docName',
            text: 'Document Name',
            formatter: (cell, row) => cell,
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'percent',
            text: 'Progress',
            formatter: (cell, row) => <ProgressBar now={cell} label={`${cell}%`} style={{width: "50%"}} />,
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'subjectName',
            text: 'Uploaded to',
            formatter: (cell, row) => cell,
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'errorMsg',
            text: 'Error Message',
            formatter: (cell, row) => cell,
            sort: true,
            filter: textFilter()
        }];

        if (uploading){
            return (
                <div className={'d-flex flex-column justify-content-center align-items-center'} style={{width: "100%"}}>
                    <h4>Uploading Status</h4>
                    <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="docName" data={Array.from(percents.values())}
                                    columns={columns} filter={filterFactory()}/>
                </div>
            )
        }

        return (
            <Fragment>
                {children}
            </Fragment>
        )
    }
}

export default BulkDocUploadProgress;