import React, {Component, Fragment} from "react";
import DualListBox from "react-dual-listbox";

export class FormikFieldDualListBox extends Component {
    constructor(props) {
        super(props);
    }

    getValue = () => {
        const {field} = this.props;

        if (!field.value) {
            return [];
        }

        return Array.from(field.value);
    };

    handleChange = (selected) => {
        const {field} = this.props;

        field.onChange({
            target: {
                name: field.name,
                value: selected
            }
        });
    };

    render() {
        const {field: {onChange, value, ...newField}, form, options = [], ...props} = this.props;

        return (
            <Fragment>
                <DualListBox options={options} onChange={this.handleChange}
                         selected={this.getValue()} {...props} {...newField}
                         className={"form-control " + ((form.touched[newField.name] && !!form.errors[newField.name]) ? "is-invalid" : "")}
                />
                {form.touched[newField.name] && form.errors[newField.name] &&
                <div className={"invalid-feedback"} style={{display: "block"}}>{form.errors[newField.name]}</div>}
            </Fragment>
        )
    }
}