/**
 * Created by pshivaraman on 4/27/18.
 */

import React, {Component, Fragment} from "react";
import filterFactory, {selectFilter, textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {DateUtils} from "../../actions/common_utils";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import {downloadDocument, fetchDocumentsByQuery, fetchOrgDocuments,} from "../../actions/doc_actions";
import {getDocTypesByEntityType} from "../../actions/fieldValue_actions";
import {connect} from "react-redux";
import {ConditionalDisplay} from "../../containers/util/shouldDisplay";
import {DocEditModal} from './doc_edit_detail_modal';
import DeleteConfirmationModal from "../delete_confirm_modal";
import { Document_Filter } from "./document_filter";

class DocManagerList extends Component {
    constructor(props) {
        super(props);

        this.onTableChange = this.onTableChange.bind(this);

        this.state = {
            avail_doc_types: [],
            page: 1,
            sizePerPage: 10,
            filter:[]
        }
    }

    componentDidMount() {
        const {entityType, entityId} = this.props;
        const {page, sizePerPage} = this.state;
        getDocTypesByEntityType(this.props.entityType, (response) => {
            this.setState({
                avail_doc_types: response.data.map(docType => {
                    return {
                        id: docType.id,
                        name: docType.type
                    }
                })
            });
            this.props.fetchDocumentsByQuery(entityType, entityId, "", null, "", "", 1, 0, 0, 0, page - 1, sizePerPage);
        });
    }

    onFinish = () => {
        const {entityType, entityId} = this.props;
        const {page, sizePerPage} = this.state;
        // refresh
        this.props.fetchDocumentsByQuery(entityType, entityId, "", null, "", "", 1, 0, 0, 0, page - 1, sizePerPage);
    }
 

    getColumns() {
        const {editable = true, onRemove, viewDoc, entityId} = this.props;

        const headerFormatter = (column, colIndex, {sortElement, filterElement}) => {
            return (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div>
                        {column.text}
                        {sortElement}
                    </div>
                    {filterElement}
                </div>
            );
        };

        const makeLink = (docId, text) => {
            return <a href="#" onClick={() => this.props.viewDoc(docId)}>{text}</a>
        };

        return [{
            dataField: "name",
            text: "Name",
            formatter: (cell, row) => makeLink(row.id, cell),
            sort: true,
          //  filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: "type",
            text: "Type",
            formatter: (cell, row) => makeLink(row.id, row.type ? (row.type.id === "OTHER" ? "Other: " + row.otherType : row.type.type) : ""),
            sort: true,
            // filter: selectFilter({
            //     options: this.state.avail_doc_types
            // }),
            headerFormatter: headerFormatter
        }, {
            dataField: "effectiveDate",
            text: "Effective Date",
            formatter: (cell, row) => makeLink(row.id, DateUtils.getDateString(cell)),
            sort: true,
            headerFormatter: headerFormatter
        }, {
            dataField: "uploaderName",
            text: "Last Updated By",
            formatter: (cell, row) => makeLink(row.id, cell),
            sort: true,
           // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: "comments",
            text: "Comments",
            formatter: (cell, row) => makeLink(row.id, cell),
            sort: false,
           // filter: textFilter(),
            headerFormatter: headerFormatter
        }, {
            dataField: "actions",
            text: "Actions",
            formatter: (cell, row) => (
                <ButtonToolbar className="p-0" style={{flexWrap: "nowrap"}}>
                    <Button variant={'link'} className="close" onClick={() => downloadDocument(row.awsS3ObjectKey)}>
                        <span aria-hidden="true" style={{paddingRight: 10}} title={"Download Document"} aria-label="Download Document">
                            <i className="fa fa-circle-down"></i>
                        </span>
                    </Button>
                    {editable &&
                    ConditionalDisplay(
                        <DocEditModal document={row} subjectId={entityId} onFinish={this.onFinish}/>
                    )
                    }
                    {editable && !row.subjectSpecific &&
                    ConditionalDisplay(
                        <DeleteConfirmationModal deleteEntity="Document" handleDeleteCallback={() => onRemove(row.id)}
                                                buttonType={'link'} jsxButtonType={<span title={"Delete Document"} aria-hidden="true" style={{padding: 10}} aria-label="Remove Document"><i class="fa fa-trash"></i></span>}
                        />
                    )
                    }
                </ButtonToolbar>
            )
        }]
    }
 onFilterClick(filters){
    const {entityType, entityId} = this.props;
     const {sizePerPage,page}= this.state; 
    // Filters
    const nameFilter = filters.name ? filters.name : "";
    const typeFilter = filters.type ? filters.type : null;
    const commentsFilter = filters.comments ? filters.comments : "";
    const uploaderNameFilter = filters.uploaderName ? filters.uploaderName : "";

    // Sort
    let nameSort = 0;
    let typeSort = 0;
    let effectiveDateSort = 0;
    let uploaderNameSort = 0;

 
    // Request Object
    this.props.fetchDocumentsByQuery(entityType, entityId, nameFilter, typeFilter, uploaderNameFilter, commentsFilter, nameSort,
        typeSort, effectiveDateSort, uploaderNameSort,0, sizePerPage);

    this.setState({
        filter:filters,
        page:1
    });
 }
    onTableChange(type, {page, sizePerPage, filters, sortField, sortOrder, cellEdit}) {
        const {entityType, entityId} = this.props;
        let { filter } = this.state;
        console.log("test",filter);
        // Filters
        const nameFilter = filter.name ? filter.name : "";
        const typeFilter = filter.type ? filter.type : null;
        const commentsFilter = filter.comments ? filter.comments : "";
        const uploaderNameFilter = filter.uploaderName ? filter.uploaderName : "";

        // Sort
        let nameSort = 0;
        let typeSort = 0;
        let effectiveDateSort = 0;
        let uploaderNameSort = 0;


        const sortDir = sortOrder === 'asc' ? 1 : -1;

        switch (sortField) {
            case "name":
                nameSort = sortDir;
                break;
            case "type":
                typeSort = sortDir;
                break;
            case "effectiveDate":
                effectiveDateSort = sortDir;
                break;
            case "uploaderName":
                uploaderNameSort = sortDir;
                break;
        }

        // Request Object
        this.props.fetchDocumentsByQuery(entityType, entityId, nameFilter, typeFilter, uploaderNameFilter, commentsFilter, nameSort,
            typeSort, effectiveDateSort, uploaderNameSort, page - 1, sizePerPage);

        this.setState({
            page: page,
            sizePerPage: sizePerPage
        });
    }

    render() {
        const {docs, totalSize} = this.props;
        const {page, sizePerPage,filter,avail_doc_types} = this.state;

        if (!docs) {
            return (
                <div>Loading...</div>
            )
        }

        const remoteSettings = {
            filter: true,
            pagination: true,
            sort: true,
            cellEdit: false
        };

        return (
            <Fragment>
                <div className="d-flex">
                    <h3 className="page_title_left">Documents</h3>
                    <Document_Filter filters={filter} onSubmit={(filter)=>this.onFilterClick(filter,this.props)} docTypes={avail_doc_types}/>
                </div>
                <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id" data={docs}
                                columns={this.getColumns()} filter={filterFactory()} remote={remoteSettings}
                                onTableChange={this.onTableChange}
                                pagination={paginationFactory({page, sizePerPage, totalSize: totalSize})}
                                defaultSorted={[{dataField:'name', order:'asc'}]}
                />
            </Fragment>
        )
    }

}

function mapStateToProps({docs}) {
    // Leave out Debriefed docs here
    let docsWODebrief = [];
    let debriefedDocs = 0;
    if (docs.data){
        for (let eachDoc of docs.data) {
            if (eachDoc.type && (eachDoc.type.id !== 'CLEARANCE_DEBRIEFING')){
                docsWODebrief.push(eachDoc);
            } else {
                debriefedDocs++;
            }
        }
    }
    return {
        docs: docsWODebrief,
        totalSize: docs.totalSize-debriefedDocs
    }
}

export default connect(mapStateToProps, {fetchDocumentsByQuery})(DocManagerList);