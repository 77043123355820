/**
 * Created by pshivaraman on 8/30/18.
 */

import React, {Component} from "react";
import {getDocTypes, getDocTypesByEntityType} from "../../../actions/fieldValue_actions";
import {Field, reduxForm} from "redux-form";
import {
    FieldRBDatePicker,
    FieldRBDropzone,
    FieldRBInput,
    FieldRBSelectWithKeys,
    FieldRBTextArea
} from "../../redux_form/field_components_rb";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {connect} from "react-redux";
import {createDocument} from "../../../actions/doc_actions";
import {showSuccessGrowl} from "../../../actions/msg_actions";
import DocUploadProgress from "../doc_upload_progress";
import DeleteConfirmationModal from "../../../components/delete_confirm_modal";

class DocNewTab extends Component {
    constructor(props) {
        super(props);

        this.onFileChange = this.onFileChange.bind(this);
        this.onTypeChange = this.onTypeChange.bind(this);

        this.state = {
            showOther: true,
            docTypes: [],
            showLoading: false,
            uploadPercent: 0
        }
    }

    componentDidMount() {
        if (this.props.entityType) {
            getDocTypesByEntityType(this.props.entityType, (response) => {
                const docTypes = response.data.map((docType) => {
                    return {
                        id: docType.id,
                        name: docType.type
                    }
                });
                if (this.props.docTypes && this.props.docTypes.length > 0){
                    let filteredDocType = [];
                    for (let eachDocType of docTypes){
                        if (this.props.docTypes.includes(eachDocType.name)){
                            console.log('Pushing', eachDocType)
                            filteredDocType.push(eachDocType);
                        }
                    }
                    this.setState({
                        docTypes: filteredDocType,
                        showOther: false
                    })
                    this.props.change("type", filteredDocType[0].id)
                } else {
                    this.setState({
                        docTypes
                    });
                    this.props.change("type", "OTHER");
                }

            });
        } else {
            getDocTypes((response) => {
                const docTypes = response.data.map((docType) => {
                    return {
                        id: docType.id,
                        name: docType.type
                    }
                });
                this.setState({
                    docTypes
                });
                this.props.change("type", "OTHER");
            });
        }
    }

    componentWillUnmount() {
        this.setState({
            showLoading: false,
            uploadPercent: 0
        });
    }

    onSubmit(values) {
        values.name = values.filename;
        this.setState({
            showLoading: true
        });
        this.props.createDocument(values, (docId) => {
            this.props.onCreate(docId, () => {
                this.props.handleClose();
                this.props.showSuccessGrowl("Document Created Successfully", "The Document was created and added successfully!");
                if (this.props.docUploadSuccessCallBack) {
                    this.props.docUploadSuccessCallBack();
                }
            });
        }, (percent) => {
            this.setState({
                uploadPercent: percent
            });
        });
    }

    onFileChange(files) {
        if (files[0]) {
            this.props.change("filename", files[0].name);
            this.props.change("filetype", files[0].type);
            this.props.change("previewUrl", files[0].preview);
        }
    }

    onTypeChange(event) {
        if (event.target.value === "OTHER") {
            this.setState({
                showOther: true
            });
        } else {
            this.setState({
                showOther: false
            });
        }
    }

    render() {
        const {handleSubmit} = this.props;
        const {showLoading, uploadPercent} = this.state;

        return (
            <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                <DocUploadProgress uploading={showLoading} percent={uploadPercent}>
                    {/*<Field name="name" component={this.renderInput} label="Name"/>*/}
                    <Field name="type" component={FieldRBSelectWithKeys} onChange={this.onTypeChange}
                           options={this.state.docTypes} label="Type" required/>
                    {this.state.showOther &&
                    <Field name="otherType" component={FieldRBInput} label="Other Type Comments" required/>
                    }
                    <Field name="effectiveDate" component={FieldRBDatePicker}
                           label="Effective Date" placeholder={'Will be today by default'}/>
                    <Field name="file" component={FieldRBDropzone} label="File" onChange={this.onFileChange}
                           multiple={false} required/>
                    {/*<Field name="filename" component={this.renderInput} label="File Name"/>*/}
                    {/*<Field name="filetype" component={this.renderInput} label="File Type"/>*/}
                    <Field name="comments" component={FieldRBTextArea} label="Comments"/>
                </DocUploadProgress>
                <Modal.Footer>
                    
                    <Button variant="primary" type="submit" disabled={showLoading}>Create</Button>
                    {/* <Button onClick={this.props.handleClose} disabled={showLoading}>Cancel</Button> */}
                    <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Add Document"
                                                  handleDeleteCallback={this.props.handleClose}/>
                </Modal.Footer>
            </form>
        )
    }
}

function validate(values) {
    const errors = {};
    if (!values.document.type) {
        errors.document = {type: "Type is required!"}
    } else if (values.type === "OTHER" && !values.otherType) {
        errors.otherType = "Other type comment is required!" ;
    }

    if (!values.file || values.file.length < 1) {
        errors.file = "You must upload a file!";
    }

    return errors;
}

export default reduxForm({
    validate, // same as validate:validate
    form: 'DocNewForm',
    enableReinitialize: true,
    initialValues: {
        document: {type: 'OTHER'}
    },
})(
    connect(null, {createDocument, showSuccessGrowl})(DocNewTab)
);