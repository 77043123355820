// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*!
 * Start Bootstrap - Simple Sidebar (http://startbootstrap.com/)
 * Copyright 2013-2016 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap/blob/gh-pages/LICENSE)
 */
body {
  overflow-x: hidden;
}

/* Toggle Styles */
#wrapper {
  padding-left: 0;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  top: 51px;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #003164;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px 15px 15px 15px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}

@media (min-width: 768px) {
  .sidebar-toggle {
    display: block;
    position: fixed;
    top: 10px;
    z-index: 99999;
    left: 200px;
    background: #003164;
    border: #000;
  }
}
@media (min-width: 600px) {
  .show-on-mobile {
    display: block !important;
  }
}
.show-on-mobile {
  display: none;
}

/* Sidebar Styles */
.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0 0 0 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav .nav-item a {
  display: block;
  text-decoration: none;
  padding: 0;
}

.sidebar-nav .nav-item a:hover {
  text-decoration: none;
  color: #0052a6;
  background: rgb(255, 255, 255);
  padding: 0;
}

.sidebar-nav .nav-item > a > span {
  padding-right: 0px;
  width: 50px;
  text-align: center;
}

.sidebar-brand.nav-item > h4 {
  margin: 0;
  padding: 15px 15px;
  color: #ffffff;
}

.sidebar-brand.nav-item > h4 > i {
  padding-right: 3px;
}

.sidebar-nav > .nav-item > a {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}

.sidebar-nav > .nav-item > a.active,
.sidebar-nav > .nav-item > a.active:hover,
.sidebar-nav > .nav-item > a.active:focus {
  color: #000000;
  background-color: #0052a6;
}

.sidebar-nav .nav-item a:active,
.sidebar-nav .nav-item a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  margin-left: 0px;
  text-indent: 0;
  /* font-size: 18px; */
  /* line-height: 60px; */
  border-bottom: 1px dashed #bbbbbb;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #888888;
  background: none;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 250px;
  }
  #wrapper.toggled {
    padding-left: 0;
  }
  #sidebar-wrapper, .left-nav-wrapper {
    width: 250px;
  }
  #wrapper.toggled #sidebar-wrapper {
    width: 0;
  }
  #page-content-wrapper {
    padding: 20px 20px 20px 20px;
    position: relative;
    padding-bottom: 100px;
  }
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}`, "",{"version":3,"sources":["webpack://./dev/assets/css/components/sidebar.scss"],"names":[],"mappings":"AAAA;;;;EAAA;AAMA;EACI,kBAAA;AAAJ;;AAGA,kBAAA;AAEA;EACI,eAAA;EAIA,yBAAA;AADJ;;AAIA;EACI,mBAAA;AADJ;;AAIA;EACI,aAAA;EACA,eAAA;EACA,SAAA;EACA,WAAA;EACA,QAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EAIA,yBAAA;AADJ;;AAIA;EACI,YAAA;AADJ;;AAIA;EACI,WAAA;EACA,kBAAA;EACA,4BAAA;AADJ;;AAIA;EACI,kBAAA;EACA,oBAAA;AADJ;;AAIA;EACI;IACI,cAAA;IACA,eAAA;IACA,SAAA;IACA,cAAA;IACA,WAAA;IACA,mBAAA;IACA,YAAA;EADN;AACF;AAIA;EACI;IACI,yBAAA;EAFN;AACF;AAMA;EACI,aAAA;AAJJ;;AASA,mBAAA;AAEA;EACI,kBAAA;EACA,MAAA;EACA,YAAA;EACA,SAAA;EACA,gBAAA;EACA,gBAAA;AAPJ;;AAUA;EACI,iBAAA;EACA,iBAAA;AAPJ;;AAUA;EACI,cAAA;EACA,qBAAA;EACA,UAAA;AAPJ;;AAUA;EACI,qBAAA;EACA,cAAA;EACA,8BAAA;EACA,UAAA;AAPJ;;AAUA;EACI,kBAAA;EACA,WAAA;EACA,kBAAA;AAPJ;;AAUA;EACI,SAAA;EACA,kBAAA;EACA,cAAA;AAPJ;;AAUA;EACI,kBAAA;AAPJ;;AAUA;EACI,cAAA;EACA,gBAAA;EACA,eAAA;AAPJ;;AAUA;;;EAGI,cAAA;EACA,yBAAA;AAPJ;;AAUA;;EAEI,qBAAA;AAPJ;;AAUA;EACI,gBAAA;EACA,cAAA;EACA,qBAAA;EACA,uBAAA;EACA,iCAAA;AAPJ;;AAWA;EACI,cAAA;AARJ;;AAWA;EACI,cAAA;EACA,gBAAA;AARJ;;AAWA;EACI;IACI,mBAAA;EARN;EAaE;IACI,eAAA;EAXN;EAcE;IACI,YAAA;EAZN;EAeE;IACI,QAAA;EAbN;EAgBE;IACI,4BAAA;IACA,kBAAA;IACA,qBAAA;EAdN;EAiBE;IACI,kBAAA;IACA,eAAA;EAfN;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
