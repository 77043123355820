/**
 * Created by pshivaraman on 4/27/18.
 */

import React from "react";
import PropTypes from "prop-types";
import DocDetail from "./doc_detail";
import DocManagerList from "./doc_list";
import {addDocToEntity, removeDocFromEntity} from "../../actions/doc_actions";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import DocAddModal from "./add_wizard/doc_add_modal";
import {ConditionalDisplay} from "../../containers/util/shouldDisplay";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";

class DocumentManager extends React.Component {
    constructor(props) {
        super(props);

        this.viewAdd = this.viewAdd.bind(this);
        this.viewDoc = this.viewDoc.bind(this);
        this.viewList = this.viewList.bind(this);
        this.viewUpdate = this.viewUpdate.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onRemove = this.onRemove.bind(this);

        this.state = {
            page: "list",
            cur_doc: "",
        };
    }

    componentDidMount() {
        this.viewList();
    }

    onCreate(docId, callback) {
        this.props.addDocToEntity(docId, this.props.entityType, this.props.entityId, callback);
    }

    onRemove(docId) {
        this.props.removeDocFromEntity(docId, this.props.entityType, this.props.entityId, () => {
            this.viewList();
        });
    }

    viewAdd() {
        this.setState({
            page: "add"
        });
    }

    viewUpdate(docId) {
        this.setState({
            page: "update",
            cur_doc: docId
        });
    }

    viewDoc(docId) {
        this.setState({
            page: "details",
            cur_doc: docId
        });
    }

    viewList() {
        this.setState({
            page: "none"
        });
        this.setState({
            page: "list"
        });
    }

    render() {
        const {editable = true, entityType, entityId} = this.props;

        return (
            <div>
                {ConditionalDisplay(
                    <div className="float-right">
                        <ButtonToolbar>
                            {editable &&
                            <Button variant="primary" className="mb-3" onClick={this.viewAdd}>Add Documents</Button>
                            }
                        </ButtonToolbar>
                    </div>
                )}
                 {/* <h3 className="page_title_left">Documents</h3> */}
                {this.state.page != "none" &&
                <DocManagerList onRemove={this.onRemove} editable={editable} entityType={entityType}
                                entityId={entityId} viewDoc={this.viewDoc}/>
                }
                {this.state.page === "add" &&
                <DocAddModal entityType={entityType} entityId={entityId} viewList={this.viewList} onCreate={this.onCreate}/>
                }
                {this.state.page === "details" &&
                <DocDetail docId={this.state.cur_doc} viewList={this.viewList}/>
                }
            </div>
        )
    }
}

DocumentManager.propTypes = {
    entityType: PropTypes.string,
    entityId: PropTypes.string,
    editable: PropTypes.bool
};

export default connect(null, {addDocToEntity, removeDocFromEntity})(DocumentManager);