import { Form } from "formik";
import React, { useEffect, useState } from "react";
import { Fragment } from "react"
import { CommonUtils } from "../actions/common_utils";
import { isNumber } from "lodash";

export const CustomPagination = ({ totalPage, totalItems, onChange }) => {
    const [sizeperpage, setSizeperpage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const getPageNumbers = () => {
        const range = 2; // Number of pages to show on either side of the current page
        let start = Math.max(currentPage - range, 1);
        let end = Math.min(currentPage + range, totalPage);

        // Adjust start or end if we have less than 5 pages in the range
        if (currentPage - start < range) {
            end = Math.min(start + 4, totalPage);
        }
        if (end - currentPage < range) {
            start = Math.max(end - 4, 1);
        }

        return Array.from({ length: (end - start + 1) }, (_, i) => start + i);
    };

    const handlePageChange = (cPage) => {
        if (currentPage >= 1 && currentPage <= totalPage) {
            setCurrentPage(cPage);
            onChange(cPage, sizeperpage)
        }
    };

    const handleSizeChange = (e) => {
        if (e.target.value) {
            setSizeperpage(e.target.value);
            onChange(1, e.target.value);
            setCurrentPage(1);
        }
    }

    let pageNumberOption = [{ id: 5, name: "5" }, { id: 10, name: "10" }, { id: 20, name: "20" }, { id: 30, name: "30" }, { id: 40, name: "40" }]

    return (<Fragment>
        <div className="custom_pagination pagination mt-3 align-items-center">
            { totalItems > 0 &&
                <>
                    <div className="align-left">

                        <select required="" name="type" class="select_pagesize"
                            value={sizeperpage} onChange={handleSizeChange}
                        >
                            {pageNumberOption && pageNumberOption.map(option => (
                                <option value={option.id}>{option.name}</option>
                            ))}
                        </select>

                    </div>
                    <div className="align-right">
                        <ul className="pagination react-bootstrap-table-page-btns-ul m-0">
                            {currentPage > 3 && totalPage > 5 &&
                                <li className="page-item">
                                    <a className="page-link" disabled={currentPage === 1} onClick={() => handlePageChange(1)}>
                                        <i className="fa fa-angles-left"></i>
                                    </a>
                                </li>
                            }
                            {currentPage > 1 &&
                                <li className="page-item">
                                    <a className="page-link" disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>
                                        <i className="fa fa-angle-left"></i>
                                    </a>
                                </li>
                            }
                            {getPageNumbers().map((page) => (
                                <li className={currentPage === page ? 'active' : 'page-item'}>
                                    <a
                                        key={page}
                                        onClick={() => handlePageChange(page)}
                                        className="page-link"
                                    >
                                        {page}
                                    </a>
                                </li>
                            ))}
                            {currentPage < totalPage && <li className="page-item">
                                <a className="page-link" disabled={currentPage === totalPage} onClick={() => handlePageChange(currentPage + 1)}>
                                    <i className="fa fa fa-angle-right"></i>
                                </a>
                            </li>}
                            {currentPage < totalPage - 2 && totalPage > 5 && <li className="page-item">
                                <a className="page-link" disabled={currentPage === totalPage} onClick={() => handlePageChange(totalPage)}>
                                    <i className="fa fa fa-angles-right"></i>
                                </a>
                            </li>}
                        </ul>
                        {/* <span>{currentPage*itemsPerPage}/{totalItems}</span> */}
                    </div>
                </>
            }
        </div>
    </Fragment>)
}