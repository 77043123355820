import React from "react";
import ChatBot, { BotOptionsContext, PathsContext } from "react-chatbotify";
import { queryChatbot } from "../actions/chatbot_actions";
import { useEffect, useState } from "react";
import bubbleIcon from "../../dev/assets/images/icons/chat.png";
import { ChatBotBottomOption } from "./chatbotSelectOption";
import { param } from "jquery";

export const ChatBotComponent = () => {
    const [docType, setDocType] = useState("FSO Toolkit")
    const [optionList, setOptionList] = useState(null);
    const [questionList, setQuestionList] = useState([]);
    const [msgHistory, setMsgHistory] = useState([]);
    useEffect(() => {
        setOptionList({
            isOpen: false,
            header: { title: "Tru-vetting" },
            emoji: { disabled: true },
            fileAttachment: { disabled: true },
            chatHistory: { disabled: true },
            notification: { disabled: true },
            //footer: { text: <ChatBotBottomOption handleOptionChange={handleOptionChange} /> },
            footer: { text: "" },
            tooltip: {
                mode: "NEVER",
                text: "Ask FSO Companion"
            },
            theme: {
                primaryColor: '#003164',
                secondaryColor: '#003e7e',
            },
            audio: {
                disabled: true,
            },
            chatButton: {
                icon: bubbleIcon,
            }
        })
    }, [])

    let has_error = false;
    const flow = {
        start: {
            message: (async (params) => await params.injectMessage(<CustomBubble message={"Ask FSO Companion!"} />)),
            path: "loop"
        },
        loop: {
            message: async (params) => {
                await Chat(params);
                // params.injectMessage(<CustomBubble message={"Need More Context?"} />);
            },
            // options: ["Yes","No"],
            file: async () => { return "loop" },
            path: (params) => {
                if (has_error) {
                    return "start"
                }
                if (params.userInput === "Yes" || params.userInput === "No")
                    return "intraction";
                else return "loop";
            }
        },
        intraction: {
            message: async (params) => {
                await Chat(params);
            },
            path: () => {
                if (has_error) {
                    return "start"
                }
                return "loop"
            }
        },
    }
    const Chat = async (params) => {
        try {

            let cory = params.userInput;
            if (params.userInput === "No") {
                return;
            }
            if (params.userInput === "Yes") {
                cory = questionList.slice(-1)[0];
            } else {
                setQuestionList(prev => [...prev, cory]);
            }
            let result = await queryChatbot(cory, docType, msgHistory);
            console.log("chatbot result is :", result);
            if (result && result.message.length > 0) {
                let filteredData = result.message.filter(item => item.message != null)
                if (filteredData === null || filteredData.length === 0) {
                    let _error = "I am sorry, but I could not find anything related your question.<br />Can you please provide more context or clarify your question?"
                    params.injectMessage(<CustomBubble message={_error} />);
                    new Promise(resolve => setTimeout(resolve, 30));
                }
                else {
                    filteredData.forEach(async (element) => {
                        if (element.message) {
                            params.injectMessage(<CustomBubble message={element.message.replace(/\n/g, "<br />")} />);
                            new Promise(resolve => setTimeout(resolve, 30));
                            if (element.sources && element.sources.length > 0) {
                                element.sources.forEach((file) => {
                                    params.injectMessage(<FileDownloadLink url={file.sourceURL} filename={file.filename} />);
                                    new Promise(resolve => setTimeout(resolve, 30));
                                })
                            }
                        }
                    });
                }
            }

            // Update chatbot message history
            if (result && result.history) {
                setMsgHistory(result.history);
            }
        } catch (error) {
            console.error('An error occurred:', error.message);
            await params.injectMessage("Unable to load model, is your API Key valid?");
            has_error = true;
        }
    }
    const CustomBubble = ({ message }) => {
        return (<div className="rcb-bot-message-container">
            <div className="rcb-bot-message rcb-bot-message-entry" style={{ backgroundColor: "#e9f3ff", color: "#000000", maxWidth: "70%" }} dangerouslySetInnerHTML={{ __html: message }} />
        </div>)
    }

    const FileDownloadLink = ({ filename, url }) => {
        return (<div className="rcb-bot-message-container">
            <div className="rcb-bot-message rcb-bot-message-entry" style={{ backgroundColor: "#e9f3ff", color: "#000000", maxWidth: "70%" }}>
                <a href={url} target="_blank" style={{ color: "#000000" }} >{filename}</a>
            </div>
        </div>)
    }
    return (
        <BotOptionsContext.Provider value={{ botOptions: optionList, setBotOptions: setOptionList }}>
            {optionList && <ChatBot options={optionList} flow={flow} />}
        </BotOptionsContext.Provider>
    )
}