/**
 * Created by Shiva on 1/30/19.
 */

import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {CUR_ORG} from "../actions/session_constants";

class EntityIssue extends React.Component {
    constructor(props) {
        super(props);

        this.getIssues = this.getIssues.bind(this)
    }

    getIssues() {
        const activeIssues = this.props.issueEntity.issues && this.props.issueEntity.issues.filter(issue => {
            return issue.active && (issue.orgId === sessionStorage.getItem(CUR_ORG) || (issue.entityType.id !== 'SUBJECT'))
        })
            .map(issue => {
                if (issue.name){
                    return <li key={issue.id}><Link to={`/org/dash/task/${issue.id}`}>{issue.name} ({issue.status})</Link></li>
                }
                return <li key={issue.id}>{issue.status}</li>
            });
        return (
            <ul>
                {activeIssues}
            </ul>
        )
    }

    render() {

        return (
            <Fragment>
                <h6>Issues</h6>
                {this.getIssues()}
                <br/>
            </Fragment>
        )
    }
}

EntityIssue.propTypes = {
    issueEntity: PropTypes.object.isRequired,
};

export default connect(null, null)(EntityIssue);