/**
 * Upload component for csv files asking for the header line
 */
import React from "react";
import {connect} from "react-redux";
import {
    BADGE_AND_BACKGROUND_DOC,
    DISS_ELIGIBILITY_DOC,
    DONNA_MASTER_ROSTER_DOC,
    EMAIL_DOC,
    HR_IMPORT_DOC,
    JPAS_ELIGIBILITY_DOC
} from "../actions/person_csvupload_action"
import Modal from "react-bootstrap/Modal";
import {fetchCurOrg} from '../actions/org_actions';
import {fetchOrgFacilities} from '../actions/facility_actions';
import {CommonUtils} from "../actions/common_utils";
import {CUR_ORG} from "../actions/session_constants";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import {Button, ButtonToolbar, Card, Col, Form} from "react-bootstrap";
import {FormikFieldDropzone} from "./formik/formik_field_dropzone";

class DocumentUploadDialogForCSV extends React.Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.state = {
            showModal: false,
            docTypes: [DISS_ELIGIBILITY_DOC, HR_IMPORT_DOC, EMAIL_DOC, BADGE_AND_BACKGROUND_DOC].sort()
        }
    }

    open() {
        // get the last uploaded date
        this.props.fetchOrgFacilities(sessionStorage.getItem(CUR_ORG));
        this.props.fetchCurOrg();
        this.setState({showModal: true});
    }

    close() {
        this.setState({showModal: false});
    }

    onSubmit = (values) => {
        // expect only one document here
        this.props.onUpload(Array.from(values.documents)[0], values.docType, values.selectedFacility, values.fillPseudoSsn);
        this.close();
    }

    getFacilityOptions = () => {
        let {facilities} = this.props;
        if (facilities) {
            let facOptions = [{id: 'None', name: 'Select a Location'}];
            for (let facility of facilities) {
                facOptions.push({id: facility.id, name: facility.name});
            }
            return CommonUtils.getOptionsForSelect(facOptions);
        }
    }

    render() {
        const {handleSubmit, accept, facilities} = this.props;
        console.log('Accept is ', accept)
        let {multiple} = this.props;
        if (multiple === undefined) {
            multiple = true
        }
        return (
            <React.Fragment>
                <Button onClick={this.open} className="btn btn-primary" variant="success">
                    <span className="fa fa-cloud-upload" aria-hidden="true"/> Upload Subjects
                </Button>
                <Modal size="lg" show={this.state.showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Document Upload Form</Modal.Title>
                    </Modal.Header>
                    <Formik
                        initialValues={{
                            documents: "",
                            docType: DISS_ELIGIBILITY_DOC,
                            selectedFacility: 'None',
                            fillPseudoSsn: ""
                        }}
                        enableReinitialize={true}
                        onSubmit={this.onSubmit}
                        validationSchema={
                            Yup.object().shape({
                                documents: Yup.mixed().test(
                                    'test-name',
                                    'There must be a file uploaded',
                                    function (value) {
                                        return value && value.length > 0;
                                    }
                                ).nullable().required('You must upload a document'),
                                docType: Yup.string().required('Document type is required to process the upload')
                            })

                        }
                    >
                        {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Card>
                                    <Card.Header>Select CSV to Upload</Card.Header>
                                    <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} md="12" controlId={'documents'}>
                                                <Field
                                                    name="documents"
                                                    component={FormikFieldDropzone}
                                                    accept={accept}
                                                    multiple={multiple}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} md="6" controlId={'docType'}>
                                                <Form.Label>Required Document Type</Form.Label>
                                                <Form.Control required as="select" name={'docType'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isInvalid={touched.docType && !!errors.docType}
                                                              value={values.docType}
                                                >
                                                    {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(this.state.docTypes))}
                                                </Form.Control>
                                            </Form.Group>
                                            {facilities && facilities.length > 0 &&
                                            <Form.Group as={Col} md="6" controlId={'selectedFacility'}>
                                                <Form.Label>Select a target facility (Optional)</Form.Label>
                                                <Form.Control as="select" name={'selectedFacility'}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              isInvalid={touched.selectedFacility && !!errors.selectedFacility}
                                                              value={values.selectedFacility}
                                                >
                                                    {this.getFacilityOptions()}
                                                </Form.Control>
                                            </Form.Group>
                                            }
                                        </Form.Row>
                                        <Form.Row>
                                            {(HR_IMPORT_DOC === values.docType) &&
                                            <Form.Group as={Col} md="6" controlId={'fillPseudoSsn'}>
                                                <Form.Check
                                                    name={'fillPseudoSsn'}
                                                    label={'Fill Pseudo SSN (if needed)?'}
                                                    onChange={handleChange}
                                                    isInvalid={touched.fillPseudoSsn && !!errors.fillPseudoSsn}
                                                    id={'fillPseudoSsn'}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.fillPseudoSsn}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            }
                                        </Form.Row>
                                        <ButtonToolbar>
                                            <Button variant="primary" type="submit">Upload</Button>
                                            <Button variant="danger" onClick={this.close}>Cancel</Button>
                                        </ButtonToolbar>
                                    </Card.Body>
                                </Card>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            </React.Fragment>);
    }
}

function validate(values) {
    const errors = {};

    if (!values.docType) {
        errors.docType = "Document type is required to process the upload";
    }

    return errors;
}

function mapStateToProps({cur_org, smos}) {
    return {
        cur_org,
        facilities: smos
    };
}

export default connect(mapStateToProps, {fetchCurOrg, fetchOrgFacilities})(DocumentUploadDialogForCSV);
// export default reduxForm({
//     validate: validate,
//     initialValues: {
//         docType: DISS_ELIGIBILITY_DOC
//     },
//     form: 'DocumentUploadFormForCSV'
// })(
//     connect(mapStateToProps, {fetchCurOrg, fetchOrgFacilities})(DocumentUploadDialogForCSV)
// );