/**
 * Created by shivaraman on 2/7/19.
 */

import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {FieldRBDualListBox} from "../redux_form/field_components_rb";
import {Field, reduxForm} from "redux-form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {ConditionalDisplay} from "../../containers/util/shouldDisplay";

class AddFacilityModal extends React.Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);

        this.state = {
            showModal: false
        }
    }

    open() {
        this.setState({showModal: true});
        // TODO: Make asynchronous
        this.props.searchFunction(".*", () => {
            // Nothing for now.
        });
    }

    close() {
        this.setState({showModal: false});
    }

    onSubmit(values) {
        this.props.onAdd(values.selectedFacilities);
        this.close();
    }

    getAvailFacilities() {
        const {facilities} = this.props;
        if (facilities) {
            return facilities.map(facility => {
                let facObj = facility
                if (facility.facility){
                    facObj = facility.facility
                }
                return {
                    value: facObj.id,
                    label: `${facObj.name?facObj.name:facObj.id}${facObj.cageCode?' - ' + facObj.cageCode:''}`
                }
            })
        }
    }

    render() {
        const {showModal} = this.state;
        const {handleSubmit} = this.props;

        return (
            <Fragment>
                {ConditionalDisplay(
                    <Button onClick={this.open} variant="primary">Add Locations</Button>
                )}

                <Modal show={showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Locations</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <Modal.Body>
                                <Field name="selectedFacilities" label="Locations to Add" component={FieldRBDualListBox} options={this.getAvailFacilities()} />
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonToolbar>
                                <Button variant="primary" type="submit">Add Locations</Button>
                                <Button variant="danger" onClick={this.close}>Cancel</Button>
                            </ButtonToolbar>
                        </Modal.Footer>
                    </form>
                </Modal>
            </Fragment>
        )
    }
}

AddFacilityModal.propTypes = {
    onAdd: PropTypes.func.isRequired,
    searchFunction: PropTypes.func.isRequired,
    existingFacilities: PropTypes.arrayOf(PropTypes.object),
};

function mapStateToProps({facility_results}) {
    return {
        facilities: facility_results
    }
}

export default reduxForm({
    form: 'AddFacilityForm'
})
(connect(mapStateToProps, null)(AddFacilityModal));