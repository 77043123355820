import React, {Component} from "react";
import ReactLoading from "react-loading";
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";

class LoadingSpinner extends Component {
    constructor(props) {
        super(props);

        this.timeIncrementMs = 50;
        this.showSpinnerIfReturnGreaterThanMs = 200;
        this.state = {
            msElapsed: 0,
            showSpinner: false
        };
    }

    componentWillUnmount() {
        clearInterval(this.incrementer);
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.axiosLoading && !this.props.axiosLoading) {
            this.incrementer = setInterval(() =>
                    this.setState({
                        msElapsed: this.state.msElapsed + this.timeIncrementMs
                    })
                , this.timeIncrementMs);
        } else if (this.props.axiosLoading && !nextProps.axiosLoading) {
            this.handleClose();
        }

        if (nextProps.axiosLoading && nextState.msElapsed > this.showSpinnerIfReturnGreaterThanMs && !nextState.showSpinner) {
            clearInterval(this.incrementer);
            this.setState({
                showSpinner: true
            });
        }
    }

    handleClose() {
        clearInterval(this.incrementer);
        this.setState({showSpinner: false});
    }

    render() {
        return (
            <Modal show={(this.state.showSpinner && this.props.axiosLoading)} onHide={this.handleClose} className="loading-modal" keyboard={false}
                   backdrop="static" size="sm" backdropClassName="loading-modal-backdrop">
                <ReactLoading type="spin" color="#fff" height={'50px'} width={'50px'}/>
                {this.props.loadingPercentage > -1 && <ProgressBar now={this.props.loadingPercentage} />}
            </Modal>
        );
    }
}

function mapStateToProps({axiosLoading, loadingPercentage}) {
    return {
        axiosLoading,
        loadingPercentage
    }
}

export default connect(mapStateToProps, {})(LoadingSpinner);