import React, {Component, Fragment} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {showSuccessGrowl} from "../../actions/msg_actions";
import {addRevisionToDoc} from "../../actions/doc_actions";
import {Field, reduxForm} from "redux-form";
import {FieldRBDatePicker, FieldRBDropzone, FieldRBTextArea} from "../redux_form/field_components_rb";
import {connect} from "react-redux";
import DocUploadProgress from "./doc_upload_progress";

class DocAddRevisionModal extends Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onFileChange = this.onFileChange.bind(this);

        this.state = {
            showModal: false,
            showLoading: false,
            uploadPercent: 0
        }
    }

    open() {
        this.setState({
            showModal: true,
            showLoading: false,
            uploadPercent: 0
        });
    }

    handleClose() {
        this.setState({
            showModal: false,
            showLoading: false,
            uploadPercent: 0
        });

        this.props.change("filename", null);
        this.props.change("filetype", null);
        this.props.change("previewUrl", null);
        this.props.change("file", null);
        this.props.change("comments", null);
    }

    onSubmit(values) {
        this.setState({
            showLoading: true
        });
        this.props.addRevisionToDoc(this.props.docId, values, () => {
            this.handleClose();
            this.props.showSuccessGrowl("Revision Created Successfully", "The Revision was created and added successfully!");
            this.props.onFinish();
        }, (percent) => {
            this.setState({
                uploadPercent: percent
            });
        });
    }

    onFileChange(files) {
        if (files[0]) {
            this.props.change("filename", files[0].name);
            this.props.change("filetype", files[0].type);
            this.props.change("previewUrl", files[0].preview);
        }
    }

    render() {
        const {showModal, showLoading, uploadPercent} = this.state;
        const {handleSubmit} = this.props;
        return (
            <Fragment>
                <Button onClick={this.open} variant="primary">Add Revision</Button>

                <Modal show={showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Revision</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <Modal.Body>
                            <DocUploadProgress uploading={showLoading} percent={uploadPercent}>
                                <Field name="file" component={FieldRBDropzone} label="File" onChange={this.onFileChange}
                                       multiple={false} required/>
                                <Field name="effectiveDate" component={FieldRBDatePicker}
                                       label="Effective Date" placeholder={'Will be today by default'}/>
                                <Field name="comments" component={FieldRBTextArea} label="Comments"/>
                            </DocUploadProgress>
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonToolbar>
                                <Button variant="primary" type="submit" disabled={showLoading}>Add</Button>
                                <Button variant="danger" onClick={this.handleClose}
                                        disabled={showLoading}>Cancel</Button>
                            </ButtonToolbar>
                        </Modal.Footer>
                    </form>
                </Modal>
            </Fragment>
        )
    }
}

function validate(values) {
    const errors = {};
    if (!values.file || values.file.length < 1) {
        errors.file = "You must upload a file!";
    }

    return errors;
}

export default reduxForm({
    form: "DocAddRevisionForm",
    validate
})(
    connect(null, {showSuccessGrowl, addRevisionToDoc})(DocAddRevisionModal)
);