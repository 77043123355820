/**
 * Created by pshivaraman on 6/19/19.
 */

import React, {Component} from "react";
import Card from "react-bootstrap/Card";
import PropTypes from "prop-types";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";

class CollapsibleCard extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            open: false,
        };
    }

    componentDidMount() {
        const {defaultOpen} = this.props;

        if (defaultOpen) {
            this.setState({open: true});
        }
    }

    render() {
        const {children, header} = this.props;
        const {open} = this.state;

        return (
            <Card className={'mb-3'}>
                <Card.Header className="clickable" as={"h5"} onClick={() => this.setState({open: !open})}>
                    {header}
                    <span className="close" aria-hidden="true" aria-label="Toggle Panel">
                        <i className={`fa fa-chevron-${open ? 'up' : 'down'}`}/>
                    </span>
                </Card.Header>

                <Collapse in={open}>
                    <div>
                        <Card.Body>
                            {children}
                        </Card.Body>
                    </div>
                </Collapse>
            </Card>
        )
    }
}

CollapsibleCard.propTypes = {
    header: PropTypes.string.isRequired,
    defaultOpen: PropTypes.bool
}

export default CollapsibleCard;