/**
 * Created by Shiva on 1/25/19.
 */

import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class DeleteConfirmationModal extends React.Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.delete = this.delete.bind(this);

        this.state = {
            showModal: false,
        }
    }

    open() {
        this.setState({showModal: true});
    }

    close() {
        this.setState({showModal: false});
    }

    delete() {
        if (this.props.entityId) {
            this.props.handleDeleteCallback(this.props.entityId);
        } else {
            this.props.handleDeleteCallback();
        } 
        this.close();
    }

    getButton = () => {
        const {buttonType, buttonText, jsxButtonType, customButton} = this.props;

        if (customButton){
            return customButton(this.open);
        }

        if (!jsxButtonType){
            return <Button onClick={this.open} variant={buttonType? buttonType: "danger"}>{buttonText ? buttonText : "Delete"}</Button>;
        } else {
            return <Button onClick={this.open} className={'close'} variant={buttonType? buttonType: "danger"}>{jsxButtonType}</Button>
        }
    }
    render() {
        const {showModal} = this.state;
        const {buttonText, actionText, buttonType} = this.props;

        return (
            <Fragment>
                <Modal show={showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>{buttonText ? buttonText : "Delete"} {this.props.deleteEntity}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to {actionText ? actionText : "delete"} this {this.props.deleteEntity}?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.close} variant="primary">No</Button>
                        <Button onClick={this.delete} className="btn-danger" variant={buttonType? buttonType: "danger"}>Yes</Button>
                    </Modal.Footer>
                </Modal>

                {this.getButton()}

            </Fragment>
        )
    }
}

DeleteConfirmationModal.propTypes = {
    deleteEntity: PropTypes.string.isRequired,
    handleDeleteCallback: PropTypes.func.isRequired,
    entityId: PropTypes.string,
    buttonText: PropTypes.node,
    actionText: PropTypes.string,
    buttonType: PropTypes.string,
    jsxButtonType: PropTypes.node,
    customButton: PropTypes.func
};

export default connect(null, null)(DeleteConfirmationModal);