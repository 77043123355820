import React, {Component, Fragment} from "react";
import {Field, reduxForm} from "redux-form";
import {FieldRBDropzone} from "../redux_form/field_components_rb";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {connect} from "react-redux";
import {showSuccessGrowl} from "../../actions/msg_actions";
import {createOneOfManyDocuments, beginBulkUploadForDocs} from "../../actions/doc_actions";
import BulkDocUploadProgress from "./bulk_doc_upload_progress";
import Card from "react-bootstrap/Card";

class BulkDocsNewTab extends Component {
    constructor(props) {
        super(props);

        this.onFileChange = this.onFileChange.bind(this);

        this.state = {
            showOther: true,
            showModal: false,
            showLoading: false,
            uploadPercents: new Map()
        }

    }

    handleOpen = () => {
        this.setState({showModal: true});
    }

    handleClose = () => {
        this.setState({
            showModal: false,
            showLoading: false,
            uploadPercents: new Map()
        });
        this.props.change("files",[])
    }

    componentWillUnmount() {
        this.setState({
            showLoading: false,
            uploadPercents: new Map()
        });
    }

    onSubmit(values) {
        this.props.beginBulkUploadForDocs();
        this.setState({
            showLoading: true
        });
        let {uploadPercents} = this.state;
        for (let file of values.files){
            uploadPercents.set(file.name, {percent: 0, docName: file.name});
        }
        this.setState({
            uploadPercents
        });
        for (let file of values.files){
            this.props.createOneOfManyDocuments(file, (percent, fileName) => {
                let {uploadPercents} = this.state;
                uploadPercents.get(fileName).percent = percent;
                this.setState({
                    uploadPercents
                });
            });
        }
    }

    onFileChange(files) {
    }

    render() {
        const {handleSubmit, docsAndStatus} = this.props;
        const {showLoading, uploadPercents} = this.state;
        console.log('In render', docsAndStatus)

        if (docsAndStatus){
            for (let docAndStatus of docsAndStatus){
                if (uploadPercents.has(docAndStatus.docName)) {
                    uploadPercents.get(docAndStatus.docName).errorMsg = docAndStatus.errorMsg;
                    uploadPercents.get(docAndStatus.docName).subjectName = docAndStatus.subjectName;
                }
            }
        }
        return (
            <Fragment>
                <Button onClick={this.handleOpen} className="btn btn-primary" variant="success">
                    <span className="fa fa-cloud-upload" aria-hidden="true"/> Upload Subject Documents
                </Button>
                <Modal show={this.state.showModal} onHide={this.handleClose} size={'lg'}>
                    <Modal.Header closeButton>
                        <Modal.Title>Documents Upload</Modal.Title>
                    </Modal.Header>
                    <Card>
                        <Card.Body>
                            <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                                <BulkDocUploadProgress uploading={showLoading} percents={uploadPercents}>
                                    <Field name="files" component={FieldRBDropzone} label="Compliance Documents" onChange={this.onFileChange}
                                           multiple={true} required/>
                                    <Modal.Footer>
                                        <Button onClick={this.handleClose} disabled={showLoading}>Cancel</Button>
                                        <Button variant="primary" type="submit" disabled={showLoading}>Bulk Upload</Button>
                                    </Modal.Footer>
                                </BulkDocUploadProgress>
                            </form>
                        </Card.Body>
                    </Card>
                </Modal>
            </Fragment>

        )
    }
}

function validate(values) {
    const errors = {};

    console.log('Files are ', values)
    if (!values.files || values.files.length < 1) {
        errors.files = "You must upload a file!";
    }

    return errors;
}

function mapStateToProps({docsAndStatus}) {
    return {
        docsAndStatus
    }
}

export const BulkDocsNew = reduxForm({
    validate, // same as validate:validate
    form: 'BulkDocsNewForm',
    enableReinitialize: true,
    initialValues: {
    },
})(
    connect(mapStateToProps, {createOneOfManyDocuments, beginBulkUploadForDocs, showSuccessGrowl})(BulkDocsNewTab)
);