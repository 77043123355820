/**
 * Created by pshivaraman on 11/12/18.
 */

import React, { Component, Fragment } from "react";
import { FieldRBCheckbox, FieldRBInput } from "../redux_form/field_components_rb";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { showSuccessGrowl, showErrorGrowl } from "../../actions/msg_actions";
import { connect } from "react-redux";
import { updateUserPassword } from "../../actions/user_actions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { USER_ID, USER_NAME, logoutUser } from "../../actions/auth_actions";
import { Col, Row, Form, Alert,ButtonToolbar,Card } from "react-bootstrap";
import PropTypes from "prop-types";
 

class AccountPasswordResetModal extends Component {
    constructor(props) {
        super(props);
    } 
    onSubmit(values) {
        if (values.password || values.qrReset === true) {
            values.userId = sessionStorage.getItem(USER_ID);
            this.props.updateUserPassword(values, () => {
                // this.props.history.push("/account/profile");
                this.props.showSuccessGrowl("User Profile Changed", "User Profile changed successfully.");
                this.props.change("password", "");
                this.props.change("passwordConf", "");
                this.props.logoutUser();
                this.props.onClose();
            });
        }
    } 

    render() { 
        const { handleSubmit, error,change, showModal,onClose} = this.props; 
        return (
            <Fragment>
                <Modal className="lg" key={showModal} show={showModal} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    
                           <Row>
                               <Col md={{span: 12, order: 'last'}}>
                                   <Alert variant={'info'}>
                                       <Alert.Heading>Password Criteria</Alert.Heading>
                                       <ul>
                                           <li>At least 8 characters</li>
                                           <li>At least 1 lower case letter</li>
                                           <li>At least 1 upper case letter</li>
                                           <li>At least 1 number</li>
                                           <li>At least 1 of the following (!,@,#,$,%,&,*,?)</li>
                                       </ul>
                                   </Alert>
                               </Col>
                                   <Col md={{ span: 12, order: 'first' }}>
                                       <div className="resetqr">
                                           {/* <h5>Reset Second Factor Code</h5> */}
                                           <Field name="qrReset" component={FieldRBCheckbox} label="Reset Second Factor Code"
                                               horizontal />
                                       </div>
                                       <Field name="password" component={FieldRBInput} type="password" label="Password" />
                                       <Field name="passwordConf" component={FieldRBInput} type="password"
                                           label="Confirm Password" />
                                       <ButtonToolbar className={'pull-right'}>
                                           <Button variant="primary" type="submit">Submit</Button>
                                           <Button variant="secondary" type="button" onClick={onClose}>Cancel</Button>
                                       </ButtonToolbar>
                                   </Col>
                           </Row>
                      
               </Form>
                </Modal.Body>
                
                </Modal>
            </Fragment>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.password) {
        errors.password = "Enter a password";
    } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&])[A-Za-z\d#@$!%*?&]{8,}$/.test(values.password)) {
        errors.password = "Password does not meet criteria!";
    }else if (values.password !== values.passwordConf) {
        errors.password = "Passwords must match!";
        errors.passwordConf = "Passwords must match!";
    }

    return errors;
}
 

export default reduxForm({
    validate, 
    form: "UserProfileUpdateForm"
})(connect(null, { updateUserPassword, showSuccessGrowl, logoutUser })(AccountPasswordResetModal));

AccountPasswordResetModal.propTypes = {
    showModal: PropTypes.bool,
    onClose: PropTypes.func
}