// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 640px) {
  .steps-demo .p-steps {
    height: 350px;
  }
  .steps-demo .p-steps > ul {
    flex-direction: column;
    height: 100%;
  }
  .steps-demo .p-steps > ul .p-steps-item {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: center;
  }
  .steps-demo .p-steps > ul .p-steps-item:before {
    position: static;
    left: auto;
    top: auto;
    margin-top: 0;
    border-left: 1px solid var(--surface-d);
    border-top: 0 none;
    width: auto;
    height: 100%;
    margin-left: 1rem;
  }
  .steps-demo .p-steps > ul .p-steps-item .p-menuitem-link {
    flex-direction: row;
    overflow: visible;
  }
  .steps-demo .p-steps > ul .p-steps-item .p-menuitem-link .p-steps-title {
    margin: 0 0.5rem 0;
  }
  .steps-demo .p-steps > ul .p-steps-item:last-child {
    flex-grow: 0;
  }
  .steps-demo .p-steps > ul .p-steps-item:last-child .p-menuitem-link {
    padding: 0;
  }
  .steps-demo .p-steps > ul .p-steps-item:last-child:before {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./dev/assets/css/components/stepper.scss"],"names":[],"mappings":"AAAA;EACE;IACE,aAAA;EACF;EACA;IACE,sBAAA;IACA,YAAA;EACF;EACA;IACE,8BAAA;IACA,uBAAA;IACA,uBAAA;EACF;EACA;IACE,gBAAA;IACA,UAAA;IACA,SAAA;IACA,aAAA;IACA,uCAAA;IACA,kBAAA;IACA,WAAA;IACA,YAAA;IACA,iBAAA;EACF;EACA;IACE,mBAAA;IACA,iBAAA;EACF;EACA;IACE,kBAAA;EACF;EACA;IACE,YAAA;EACF;EACA;IACE,UAAA;EACF;EACA;IACE,aAAA;EACF;AACF","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
