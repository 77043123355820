import React, {Component, Fragment} from "react";
import Dropzone from "react-dropzone";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import {downloadDocument} from "../../actions/doc_actions";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Button from "react-bootstrap/Button";

export class FormikFieldDropzone extends Component {
    constructor(props) {
        super(props);
    }

    getFiles = () => {
        const {field} = this.props;

        if (!field.value){
            return [];
        }

        return Array.from(field.value);
    };

    onDropFiles = (acceptedFiles, rejectedFiles) => {
        const {field, multiple = false} = this.props;
        const files = this.getFiles();

        // For Formik
        // Ensures that if multiple = false, length is 0
        if (multiple || files.length < 1) {
            const newFiles = [].concat(files, Array.from(acceptedFiles));
            field.onChange({
                target: {
                    name: field.name,
                    value: newFiles
                }
            });
        }
    };

    onChangeHandler = (e) => {
        e.preventDefault();
    };

    removeFile = (i) => {
        const {field} = this.props;

        // For Formik
        let newFiles = this.getFiles();
        newFiles.splice(i, 1);
        field.onChange({
            target: {
                name: field.name,
                value: newFiles
            }
        });
    };

    createFileItem = (file, i) => {
        let link;
        if (file.docId) {// If File has a docId, that means we can download it.
            link = <a href="#" onClick={() => downloadDocument(file)}>{file.name}</a>;
        } else {// If not, don't allow downloads.
            link = file.name;
        }
        return (
            <ListGroupItem key={i}>
                {link}
                <Button variant={'link'} className="close" onClick={() => this.removeFile(i)}>
                    <span title={"Remove File"} aria-hidden="true"><i className="fa fa-times-circle" /></span>
                </Button>
            </ListGroupItem>
        );
    };

    render() {
        const {field, form, disabled = false, children, ...props} = this.props;
        const files = this.getFiles();

        const className = `paes-dropzone${form.errors[field.name] ? " paes-dropzone-invalid" : ""}`;
        return (
            <Fragment>
                {!disabled &&
                <Dropzone {...props} className={className}
                          activeClassName="paes-dropzone-active" onChange={this.onChangeHandler}
                          onDrop={this.onDropFiles}>
                    <Fragment>
                        <div><span className="fa fa-cloud-upload"/></div>
                        <div>Drag and drop files here, or click to upload manually.</div>
                        <Form.Control.Feedback type="invalid">{form.errors[field.name]}</Form.Control.Feedback>
                    </Fragment>
                </Dropzone>
                }
                <ListGroup>
                    {files && (
                        files.map((file, i) => this.createFileItem(file, i))
                    )}
                </ListGroup>
                {children}
            </Fragment>
        )
    }
}