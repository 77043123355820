import React, {Component, Fragment} from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

class DocUploadProgress extends Component {
    render(){
        const {children, percent, uploading} = this.props;

        if (uploading){
            return (
                <div className={'d-flex flex-column justify-content-center align-items-center'} style={{width: "100%", height: "200px"}}>
                    <h4>Uploading...</h4>
                    <ProgressBar now={percent} style={{width: "50%"}} />
                </div>
            )
        }

        return (
            <Fragment>
                {children}
            </Fragment>
        )
    }
}

export default DocUploadProgress;