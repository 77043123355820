import React, { Component, Fragment, useEffect, useReducer, useRef, useState } from "react";
import { showSuccessGrowl, showErrorGrowl } from "../../actions/msg_actions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ConditionalDisplay } from "../../containers/util/shouldDisplay";
import { Col, Row } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { getNotificationPreferences, updatePreferences } from "../../actions/notification_actions";
import { getNotificationTypes } from "../../actions/fieldValue_actions";
import { connect } from "react-redux"; 
import { PropTypes } from "prop-types";

export const NotificationSettingComp = ({ showSuccessGrowl, showErrorGrowl,showModal,onClose }) => {

    const [notificationType, setNotificationType] = useState(null);
    const [notificationPreference, setNotificationPreference] = useState(null);
    const [isChecked, setIsChecked] = useState(true);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
      };

    useEffect(() => {
        getNotification();
    }, [])

    console.log(isChecked,"isChecked")

    const getNotification = async () => {
        let notificationMap = await getNotificationPreferences();
        console.log(notificationMap);
        setNotificationPreference(notificationMap)
        if(notificationMap?.disallowEmail){
            setIsChecked(false)
        }
        let notificationTypeList = await getNotificationTypes();
        let option = [];
        notificationTypeList && notificationTypeList.forEach((notificationType) => {
            if (notificationMap && notificationMap.notificationType2EmailOptionMap[notificationType.id] !== undefined)
                option.push({
                    id: notificationType.id,
                    value: notificationMap.notificationType2EmailOptionMap[notificationType.id],
                    description: notificationType.description,
                    emailable: notificationType?.emailable
                })
            else
                option.push({
                    id: notificationType.id,
                    value: true,
                    description: notificationType.description,
                    emailable: notificationType?.emailable
                })
        });
        setNotificationType(option);
    }

    const updateNotification = (type, value) => {
        setNotificationType(prevCheckboxList =>
            prevCheckboxList.map(item =>
                item.id === type.id ? { ...item, value: !item.value } : item
            )
        ); 
    };
    const onSubmit = async () => {
        let postData = {
            notificationType2EmailOptionMap: {}
        }
        notificationType.forEach(type => {
            postData.notificationType2EmailOptionMap[type.id]  = type.value;
        })
        if(!isChecked){
        postData.disallowEmail = true
        }
        console.log("post data:", postData);
        let response = await updatePreferences(postData);
        console.log("response data:", response);
        if (response.data && response.data != "") {
            showSuccessGrowl("Saved Successfully",response.data);
            onClose();
        }
    } 
  
    return ( 
        <Fragment> 
            <Modal key={showModal} show={showModal} onHide={onClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Notification Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">

                        <Form.Row>
                            <Form.Group>
                                <label> Allow All Notification For Organization</label>
                            </Form.Group>
                            <Form.Group>
                                <label class="switch">
                                    <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} />
                                    <span class="slider round"></span>
                                </label>
                            </Form.Group>
                        </Form.Row>
                        {notificationType && notificationType.map(type => (
                          type.emailable===true &&  <Row key={type.id}>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id={`switch-${type.id}`}
                                    label={"Allow email for the " + type.description}
                                    disabled={!isChecked}
                                    checked={type.value}
                                    onChange={(e) => { updateNotification(type, e.target.checked) }}
                                />
                            </Row>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" onClick={onSubmit}>Update</Button>
                    <Button variant="danger" type="button" onClick={onClose}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export const NotificationSettingModal = connect(null, {
    showSuccessGrowl,
    showErrorGrowl
})(NotificationSettingComp) ;

NotificationSettingComp.propTypes = {
    showModal: PropTypes.bool,
    onClose: PropTypes.func
}