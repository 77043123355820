import React, {Component, Fragment} from "react";
import Dropzone from "react-dropzone";
import {connect} from "react-redux";

export class FormikFileupload extends Component {
    constructor(props) {
        super(props);
    }

    

    onDropFiles = (acceptedFiles, rejectedFiles) => {
        acceptedFiles[0].docType =this.props.docType;
        this.props.onUploadnew(Array.from(acceptedFiles)[0], this.props.docType);
    };

    onChangeHandler = (e) => {  
        e.preventDefault();
    }; 

    render() {
        const {field, form, disabled = false, children, ...props} = this.props;
         return (
            <Fragment>
                {!disabled &&
                    <Dropzone {...props} style={{ innerHeight: "0", innerWidth: "0" ,cursor:"pointer"}}
                        activeClassName="" onChange={this.onChangeHandler}
                        onDrop={this.onDropFiles}>
                        <Fragment>
                            <span aria-hidden="true" style={{ padding: 10 }} title={"upload Document"} aria-label="upload Document">
                                <i class="fas fa-cloud-upload" style={{ color: "black" }}></i>
                            </span>
                        </Fragment>
                    </Dropzone>
                } 
                {children}
            </Fragment>
        )
    }
}

export const FormikFileuploadComp = connect(null, {})(FormikFileupload);