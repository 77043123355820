/**
 * Created by pshivaraman on 7/10/19.
 */

import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import Fade from "react-bootstrap/Fade";
import Toast from "react-bootstrap/Toast";

class ToastGrowler extends Component {
    constructor(props) {
        super(props);

        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: true
        }
    }

    handleClose() {
        this.setState({
            show: false
        });
        setTimeout(this.props.onClose, 1000);
    }

    render() {
        const {autohide, delay, children, animation, transition, severity = "info", closeable = true, detail = null, icon = null, summary} = this.props;
        const {show} = this.state;

        return (
            <Fragment> 
            {/* <Toast bsPrefix={`toast${severity && severity !== "" ? "-" : ""}${severity}`} show={show} autohide={autohide} delay={delay} animation={animation ? animation : true}
                   transition={transition ? transition : Fade} onClose={this.handleClose}>
                <Toast.Header bsPrefix={`toast${severity && severity !== "" ? "-" : ""}${severity}-header`} closeButton={closeable}>
                    {icon ? <span className={`fa fa-${icon} mr-2`} aria-hidden="true" style={{fontSize: "1.5em"}} />: ""}
                    <strong className="mr-auto">{summary}</strong>
                </Toast.Header>
                <Toast.Body>
                    {detail}
                </Toast.Body>
            </Toast> */}
                <Toast bsPrefix={`bg${severity && severity !== "" ? "-" : ""}${severity}`} className="fade toast d-inline-block m-1 text-white" bg={severity} onClose={this.handleClose} show={show} delay={3000} autohide>
                    <Toast.Header closeButton={closeable}>
                        {icon ? <span className={`fa fa-${icon} mr-2`} aria-hidden="true" style={{ fontSize: "1.5em" }} /> : ""}
                        <strong className="me-auto">{summary}</strong>
                    </Toast.Header>
                    <Toast.Body> {detail}</Toast.Body>
                </Toast>
            </Fragment>
        )
    }
}

ToastGrowler.propTypes = {
    severity: PropTypes.string,
    animation: PropTypes.bool,
    transition: PropTypes.any,
    autohide: PropTypes.bool.isRequired,
    delay: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    summary: PropTypes.string.isRequired,
    closeable: PropTypes.bool,
    detail: PropTypes.string,
    icon: PropTypes.string
};

export default ToastGrowler;