import React, {Component, Fragment} from "react"; 
import { Picky } from "react-picky";

export class FormikFieldMultiSelectDropDown extends Component {
    constructor(props) {
        super(props);
    }

    getValue = () => {
        const {field} = this.props;

        if (!field.value) {
            return [];
        }

        return Array.from(field.value);
    };

    handleChange = (selected) => {
        const {field} = this.props;

        field.onChange({
            target: {
                name: field.name,
                value: selected
            }
        });
    };

    render() {
        const { field: { onChange, value, ...newField }, form, options = [], ...props } = this.props;
        return (
            <Picky
                value={value}
                onChange={this.handleChange}
                options={options}
                multiple
                // includeSelectAll
                // includeFilter
                // dropdownHeight={300}
                valueKey="value"
                labelKey="label"

            />
        )
    }
}