import React, {Component, Fragment} from "react";
import DatePickerReact from "react-datepicker";
import {DateUtils} from "../../actions/common_utils";
import {getIn} from "formik";

export class FormikDatePickerComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {field, form, ...props} = this.props; // Taking the field and form props passed by formik automatically to the component and keep the remaining in props
        return (
            <Fragment>
                <DatePickerReact selected={DateUtils.getDate(field.value)}
                                 minDate={field.minDate}
                                 maxDate={field.maxDate}
                                 name={field.name}
                                 onChange={(date) => {
                                     if (date === null) {
                                         form.setFieldValue(field.name, null);
                                     } else {
                                         form.setFieldValue(field.name, date.toISOString());
                                     }
                                 }}
                                 dateFormat={"MM/dd/yyyy"} placeholderText={field.placeholder} isClearable
                                 className={"form-control " + ((form.touched[field.name] && !!form.errors[field.name]) ? "is-invalid" : "")}
                                 {...props} // pass the props passed
                />
                {getIn(form.errors, field.name) &&
                <div className={"invalid-feedback"} style={{display: "block"}}>{getIn(form.errors, field.name)}</div>}

            </Fragment>
        )
    }
}

